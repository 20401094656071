<template>
  <div>
    <v-card flat>
      <v-card-text>
        <div v-if="!loading">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-row>
                    <v-col cols="12" sm="12" md="9">
                      <v-card flat style="min-height: 200px">
                        <v-toolbar
                          class="mt-3"
                          dense
                          flat
                          style="
                            border-left: 5px solid #2196f3;
                            border-top-left-radius: 0px;
                          "
                          ><v-toolbar-title>
                            <v-icon class="mr-2" color="blue-grey"
                              >warehouse</v-icon
                            >
                            Store Overview</v-toolbar-title
                          ></v-toolbar
                        >
                        <v-card-text>
                          <table
                            :width="'100%'"
                            style="font-size: 11px"
                            v-if="stores.length > 0"
                          >
                            <thead>
                              <tr>
                                <th>Store</th>
                                <th>Last File</th>
                                <th>Total</th>
                                <th>Allocated</th>
                                <th>Avg. Age</th>
                                <th>Max Age</th>
                                <th>Unallocated</th>
                                <th>Avg. Age</th>
                                <th>Max Age</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in stores" :key="index">
                                <td class="text-center">
                                  {{ item.locationCode }}
                                </td>
                                <td class="text-center">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-chip v-on="on" x-small outlined style="border:none">
                                        <i>{{
                                          item.batchFileTime
                                        }}</i>
                                      </v-chip>
                                    </template>
                                    Last file received: {{ item.documentName }}
                                  </v-tooltip>
                                </td>
                                <td class="text-center">
                                  <v-chip
                                    x-small
                                    @click="
                                      previewStock(
                                        item.locationCode,
                                        'TOTAL_STOCK'
                                      )
                                    "
                                  >
                                    {{ item.totalStock }}
                                  </v-chip>
                                </td>
                                <td class="text-center">
                                  <v-chip
                                    x-small
                                    @click="
                                      previewStock(
                                        item.locationCode,
                                        'ALLOCATED_STOCK'
                                      )
                                    "
                                  >
                                    {{ item.totalAllocated }}
                                  </v-chip>
                                </td>

                                <td class="text-center">
                                  {{ item.allocatedAverageAge }}
                                </td>
                                <td class="text-center">
                                  {{ item.allocatedMaxAge }}
                                </td>
                                <td class="text-center">
                                  <v-chip
                                    x-small
                                    @click="
                                      previewStock(
                                        item.locationCode,
                                        'UNALLOCATED_STOCK'
                                      )
                                    "
                                  >
                                    {{ item.totalUnallocated }}
                                  </v-chip>
                                </td>
                                <td class="text-center">
                                  {{ item.unallocatedAverageAge }}
                                </td>
                                <td class="text-center">
                                  {{ item.unallocatedMaxAge }}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td
                                  class="text-center"
                                  style="border-top: 0.25px solid grey"
                                >
                                  <v-chip
                                    x-small
                                    @click="previewStock(null, 'TOTAL_STOCK')"
                                  >
                                    {{ totalStock }}
                                  </v-chip>
                                </td>
                                <td
                                  class="text-center"
                                  style="border-top: 0.25px solid grey"
                                >
                                  <v-chip
                                    x-small
                                    @click="
                                      previewStock(null, 'ALLOCATED_STOCK')
                                    "
                                  >
                                    {{ totalAllocated }}
                                  </v-chip>
                                </td>
                                <td
                                  class="text-center"
                                  style="border-top: 0.25px solid grey"
                                ></td>
                                <td
                                  class="text-center"
                                  style="border-top: 0.25px solid grey"
                                ></td>
                                <td
                                  class="text-center"
                                  style="border-top: 0.25px solid grey"
                                >
                                  <v-chip
                                    x-small
                                    @click="
                                      previewStock(null, 'UNALLOCATED_STOCK')
                                    "
                                  >
                                    {{ totalUnallocated }}
                                  </v-chip>
                                </td>
                                <td
                                  class="text-center"
                                  style="border-top: 0.25px solid grey"
                                ></td>
                                <td
                                  class="text-center"
                                  style="border-top: 0.25px solid grey"
                                ></td>
                              </tr>
                            </tfoot>
                          </table>
                          <v-list-item v-else>
                            <v-list-item-content class="text-center">
                              <span style="font-size: 12px; color: grey"
                                >No stock available</span
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                      <v-card
                        flat
                        :loading="loadingDiscrepancy"
                        style="min-height: 200px"
                      >
                        <v-toolbar
                          class="mt-3"
                          dense
                          flat
                          style="
                            border-left: 5px solid orange;
                            border-top-left-radius: 0px;
                          "
                          ><v-toolbar-title>
                            <v-icon class="mr-2" color="orange">error</v-icon>
                            Stuff Discrepancies
                            <v-btn class="ml-2" icon @click="getDiscrepancies()"
                              ><v-icon>refresh</v-icon></v-btn
                            ></v-toolbar-title
                          ></v-toolbar
                        >
                        <v-card-text>
                          <v-list dense>
                            <v-list-item v-if="discrepancies.length == 0">
                              <v-list-item-content class="text-center">
                                <span style="font-size: 12px; color: grey"
                                  >No issues</span
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              v-for="issue in discrepancies"
                              :key="issue.id"
                              @click="viewDiscrepancy(issue)"
                            >
                              <!-- <v-list-item v-for="issue in discrepancies" :key="issue.id"> -->
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ issue.ctoNo }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-chip x-small>
                                  <v-icon small left color="green"
                                    >check_circle_outline</v-icon
                                  >
                                  {{ issue.loadedPallets }} Loaded
                                  <v-icon small class="mx-1" color="red"
                                    >error</v-icon
                                  >
                                  {{ issue.issuePallets }} issue(s)
                                </v-chip>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card flat :loading="loadingVessels">
                        <v-toolbar
                          class="mt-3"
                          dense
                          flat
                          style="
                            border-left: 5px solid teal;
                            border-top-left-radius: 0px;
                          "
                          ><v-toolbar-title>
                            <v-icon class="mr-2" color="teal"
                              >directions_boat</v-icon
                            >
                            Vessel Summary
                            <v-btn class="ml-2" icon @click="getVesselSummary()"
                              ><v-icon>refresh</v-icon></v-btn
                            ></v-toolbar-title
                          >
                          <v-text-field
                            class="ml-2"
                            style="max-width: 350px"
                            prepend-inner-icon="search"
                            outlined
                            dense
                            hide-details
                            placeholder="Search"
                            v-model="search"
                          ></v-text-field>
                        </v-toolbar>
                        <v-card-text>
                          <v-divider></v-divider>
                          <v-subheader
                            style="
                              font-size: 14px;
                              font-weight: bold;
                              background: var(--v-toolbar-lighten1) !important;
                            "
                          >
                            <v-icon color="primary" class="mr-2">today</v-icon>
                            Upcoming Shipments
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-data-table
                            :headers="headers"
                            :items="vessels.upcoming"
                            :loading="loading"
                            hide-default-footer
                            disable-pagination
                            :expanded="expanded"
                            @click:row="expandRow"
                            :search="search"
                            show-expand
                          >
                          <template v-slot:[`item.vesselVoyage`]="{ item }">
                              <v-chip
                                v-if="item.movementType !='LOCAL'"
                                small
                                outlined
                                style="border: none"
                                class="ml-2"
                              >
                                {{ item.vesselVoyage }}
                              </v-chip>
                              <v-chip small
                                outlined
                                style="border: none"
                                class="ml-2" v-else>
                              LOCAL
                              </v-chip>
                            </template>
                            <template v-slot:[`item.port`]="{ item }">
                              <v-chip
                                v-if="item.port"
                                small
                                outlined
                                style="border: none"
                                class="ml-2"
                              >
                                <v-avatar size="24" left>
                                  <v-img
                                    contain
                                    v-if="item.port"
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.port
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.port }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.sailed`]="{ item }">
                              <v-icon v-if="item.sailed" color="green"
                                >check_circle_outline</v-icon
                              >
                              <v-icon v-else color="red">cancel</v-icon>
                            </template>
                            <template v-slot:[`item.allocated`]="{ item }">
                              <v-chip
                                small
                                v-if="item.allocated > 0"
                                :color="getPalletStatusColor('Allocated')"
                                @click="
                                  viewPallets(item, item.port, 'Allocated')
                                "
                              >
                                {{ item.allocated }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.confirmed`]="{ item }">
                              <v-chip
                                small
                                v-if="item.confirmed > 0"
                                :color="getPalletStatusColor('Confirmed')"
                                @click="
                                  viewPallets(item, item.port, 'Confirmed')
                                "
                              >
                                {{ item.confirmed }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.rejected`]="{ item }">
                              <v-chip
                                small
                                v-if="item.rejected > 0"
                                :color="getPalletStatusColor('Rejected')"
                                @click="
                                  viewPallets(item, item.port, 'Rejected')
                                "
                              >
                                {{ item.rejected }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.stuffed`]="{ item }">
                              <v-chip
                                small
                                v-if="item.stuffed > 0"
                                :color="getPalletStatusColor('Stuffed')"
                                @click="viewPallets(item, item.port, 'Stuffed')"
                              >
                                {{ item.stuffed }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.issues`]="{ item }">
                              <v-chip
                                small
                                v-if="item.issues > 0"
                                :color="
                                  getPalletStatusColor('Stuff Discrepancy')
                                "
                                @click="
                                  viewPallets(
                                    item,
                                    item.port,
                                    'Stuff Discrepancy'
                                  )
                                "
                              >
                                {{ item.issues }}
                              </v-chip>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                              <td colspan="2"></td>
                              <td class="px-1" :colspan="headers.length - 2">
                                <v-card class="my-1" flat>
                                  <v-card-text class="my-0 py-0">
                                    <table style="width: 100%">
                                      <thead
                                        style="
                                          background: transparent !important;
                                          border: none;
                                        "
                                      >
                                        <tr>
                                          <th
                                            style="
                                              background: transparent !important;
                                              border-bottom: none;
                                              font-size: 12px;
                                            "
                                          >
                                            Consignee
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Containers
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Allocated
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Confirmed
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Rejected
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Stuffed
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Issues
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        style="padding-top: 10px"
                                        class="mt-1"
                                      >
                                        <tr
                                          v-for="(
                                            consignee, index
                                          ) in item.consignees"
                                          :key="index"
                                          style="font-size: 11px"
                                        >
                                          <td class="text-center">
                                            <v-tooltip
                                              top
                                              v-if="consignee.consignee"
                                            >
                                              <template
                                                v-slot:activator="{ on }"
                                              >
                                                <v-chip
                                                  v-if="consignee.consignee"
                                                  small
                                                  class="primaryText--text"
                                                  outlined
                                                  v-on="on"
                                                  style="border: none"
                                                >
                                                  <v-avatar
                                                    size="24"
                                                    :color="
                                                      consignee.consignee &&
                                                      consignee.consignee.logo
                                                        ? 'white'
                                                        : 'secondary'
                                                    "
                                                    left
                                                  >
                                                    <v-img
                                                      style=""
                                                      v-if="
                                                        consignee.consignee.logo
                                                      "
                                                      :src="
                                                        consignee.consignee.logo
                                                      "
                                                      contain
                                                    ></v-img>
                                                    <h3
                                                      v-else
                                                      style="color: white"
                                                    >
                                                      {{
                                                        consignee.consignee.name.charAt(
                                                          0
                                                        )
                                                      }}
                                                    </h3>
                                                  </v-avatar>
                                                  <span
                                                    v-if="
                                                      consignee.consignee.alias
                                                    "
                                                  >
                                                    {{
                                                      consignee.consignee.alias
                                                    }}</span
                                                  >
                                                  <span v-else>
                                                    {{
                                                      consignee.consignee.name
                                                    }}
                                                  </span>
                                                </v-chip>
                                              </template>
                                              <span>{{
                                                consignee.consignee.name
                                              }}</span>
                                            </v-tooltip>
                                          </td>
                                          <td class="text-center">
                                            <v-chip small>{{
                                              consignee.containers.length
                                            }}</v-chip>
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.allocated > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Allocated'
                                                )
                                              "
                                              >{{ consignee.allocated }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.confirmed > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Confirmed'
                                                )
                                              "
                                              >{{ consignee.confirmed }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.rejected > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Rejected'
                                                )
                                              "
                                              >{{ consignee.rejected }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.stuffed > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Stuffed'
                                                )
                                              "
                                              >{{ consignee.stuffed }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.issues > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Stuff Discrepancy'
                                                )
                                              "
                                              >{{ consignee.issues }}</v-chip
                                            >
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </v-card-text>
                                </v-card>
                              </td>
                            </template>
                          </v-data-table>

                          <v-divider
                            v-if="
                              vessels.recentlySailed &&
                              vessels.recentlySailed.length > 0
                            "
                          ></v-divider>
                          <v-subheader
                            v-if="
                              vessels.recentlySailed &&
                              vessels.recentlySailed.length > 0
                            "
                            style="
                              font-size: 14px;
                              font-weight: bold;
                              background: var(--v-toolbar-lighten1) !important;
                            "
                          >
                            <v-icon color="primary" class="mr-2"
                              >event_available</v-icon
                            >
                            Recently Sailed
                          </v-subheader>
                          <v-divider
                            v-if="
                              vessels.recentlySailed &&
                              vessels.recentlySailed.length > 0
                            "
                          ></v-divider>
                          <v-data-table
                            v-if="
                              vessels.recentlySailed &&
                              vessels.recentlySailed.length > 0
                            "
                            :headers="headers"
                            :items="vessels.recentlySailed"
                            :loading="loading"
                            hide-default-footer
                            disable-pagination
                            :expanded="expanded"
                            @click:row="expandRow"
                            :search="search"
                            show-expand
                          >
                          <template v-slot:[`item.vesselVoyage`]="{ item }">
                              <v-chip
                                v-if="item.movementType !='LOCAL'"
                                small
                                outlined
                                style="border: none"
                                class="ml-2"
                              >
                                {{ item.vesselVoyage }}
                              </v-chip>
                              <v-chip small
                                outlined
                                style="border: none"
                                class="ml-2" v-else>
                              LOCAL
                              </v-chip>
                            </template>
                            <template v-slot:[`item.port`]="{ item }">
                              <v-chip
                                v-if="item.port"
                                small
                                outlined
                                style="border: none"
                                class="ml-2"
                              >
                                <v-avatar size="24" left>
                                  <v-img
                                    contain
                                    v-if="item.port"
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.port
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.port }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.sailed`]="{ item }">
                              <v-icon v-if="item.sailed" color="green"
                                >check_circle_outline</v-icon
                              >
                              <v-icon v-else color="red">cancel</v-icon>
                            </template>
                            <template v-slot:[`item.allocated`]="{ item }">
                              <v-chip
                                small
                                v-if="item.allocated > 0"
                                :color="getPalletStatusColor('Allocated')"
                                @click="
                                  viewPallets(item, item.port, 'Allocated')
                                "
                              >
                                {{ item.allocated }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.confirmed`]="{ item }">
                              <v-chip
                                small
                                v-if="item.confirmed > 0"
                                :color="getPalletStatusColor('Confirmed')"
                                @click="
                                  viewPallets(item, item.port, 'Confirmed')
                                "
                              >
                                {{ item.confirmed }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.rejected`]="{ item }">
                              <v-chip
                                small
                                v-if="item.rejected > 0"
                                :color="getPalletStatusColor('Rejected')"
                                @click="
                                  viewPallets(item, item.port, 'Rejected')
                                "
                              >
                                {{ item.rejected }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.stuffed`]="{ item }">
                              <v-chip
                                small
                                v-if="item.stuffed > 0"
                                :color="getPalletStatusColor('Stuffed')"
                                @click="viewPallets(item, item.port, 'Stuffed')"
                              >
                                {{ item.stuffed }}
                              </v-chip>
                            </template>
                            <template v-slot:[`item.issues`]="{ item }">
                              <v-chip
                                small
                                v-if="item.issues > 0"
                                :color="
                                  getPalletStatusColor('Stuff Discrepancy')
                                "
                                @click="
                                  viewPallets(
                                    item,
                                    item.port,
                                    'Stuff Discrepancy'
                                  )
                                "
                              >
                                {{ item.issues }}
                              </v-chip>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                              <td colspan="2"></td>
                              <td class="px-1" :colspan="headers.length - 2">
                                <v-card class="my-1" flat>
                                  <v-card-text class="my-0 py-0">
                                    <table style="width: 100%">
                                      <thead
                                        style="
                                          background: transparent !important;
                                          border: none;
                                        "
                                      >
                                        <tr>
                                          <th
                                            style="
                                              background: transparent !important;
                                              border-bottom: none;
                                              font-size: 12px;
                                            "
                                          >
                                            Consignee
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Containers
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Allocated
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Confirmed
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Rejected
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Stuffed
                                          </th>
                                          <th
                                            style="
                                              background: transparent !important;
                                              font-size: 12px;
                                            "
                                          >
                                            Issues
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        style="padding-top: 10px"
                                        class="mt-1"
                                      >
                                        <tr
                                          v-for="(
                                            consignee, index
                                          ) in item.consignees"
                                          :key="index"
                                          style="font-size: 11px"
                                        >
                                          <td class="text-center">
                                            <v-tooltip
                                              top
                                              v-if="consignee.consignee"
                                            >
                                              <template
                                                v-slot:activator="{ on }"
                                              >
                                                <v-chip
                                                  v-if="consignee.consignee"
                                                  small
                                                  class="primaryText--text"
                                                  outlined
                                                  v-on="on"
                                                  style="border: none"
                                                >
                                                  <v-avatar
                                                    size="24"
                                                    :color="
                                                      consignee.consignee &&
                                                      consignee.consignee.logo
                                                        ? 'white'
                                                        : 'secondary'
                                                    "
                                                    left
                                                  >
                                                    <v-img
                                                      style=""
                                                      v-if="
                                                        consignee.consignee.logo
                                                      "
                                                      :src="
                                                        consignee.consignee.logo
                                                      "
                                                      contain
                                                    ></v-img>
                                                    <h3
                                                      v-else
                                                      style="color: white"
                                                    >
                                                      {{
                                                        consignee.consignee.name.charAt(
                                                          0
                                                        )
                                                      }}
                                                    </h3>
                                                  </v-avatar>
                                                  <span
                                                    v-if="
                                                      consignee.consignee.alias
                                                    "
                                                  >
                                                    {{
                                                      consignee.consignee.alias
                                                    }}</span
                                                  >
                                                  <span v-else>
                                                    {{
                                                      consignee.consignee.name
                                                    }}
                                                  </span>
                                                </v-chip>
                                              </template>
                                              <span>{{
                                                consignee.consignee.name
                                              }}</span>
                                            </v-tooltip>
                                          </td>
                                          <td class="text-center">
                                            <v-chip small>{{
                                              consignee.containers.length
                                            }}</v-chip>
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.allocated > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Allocated'
                                                )
                                              "
                                              >{{ consignee.allocated }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.confirmed > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Confirmed'
                                                )
                                              "
                                              >{{ consignee.confirmed }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.rejected > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Rejected'
                                                )
                                              "
                                              >{{ consignee.rejected }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.stuffed > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Stuffed'
                                                )
                                              "
                                              >{{ consignee.stuffed }}</v-chip
                                            >
                                          </td>
                                          <td class="text-center">
                                            <v-chip
                                              small
                                              v-if="item.issues > 0"
                                              @click="
                                                viewPallets(
                                                  {
                                                    ...item,
                                                    consigneeId:
                                                      consignee.consigneeId,
                                                    consignee:
                                                      consignee.consignee,
                                                  },
                                                  item.port,
                                                  'Stuff Discrepancy'
                                                )
                                              "
                                              >{{ consignee.issues }}</v-chip
                                            >
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </v-card-text>
                                </v-card>
                              </td>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>

        <div v-else>
          <valhalla-loading :height="'80vh'" />
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="previewStockModal" width="95vw">
      <PreviewStockModal
        :key="previewKey"
        :filterType="previewStockType"
        :locationCode="previewLocationCode"
        @close="previewStockModal = false"
      />
    </v-dialog>

    <v-dialog v-model="palletModal" width="90vw">
      <v-card v-if="selectedVessel">
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                :loading="downloadingPalletExport"
                @click="downloadPalletExport()"
                ><v-icon>download</v-icon></v-btn
              >
            </template>
            Export to Excel
          </v-tooltip> -->
          <v-chip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    v-on="on"
                    :loading="downloadingPalletExport"
                    @click="downloadPalletExport()"
                  >
                    <v-icon small>download</v-icon>
                  </v-btn>
                </template>
                Export to Excel
              </v-tooltip>
              <v-icon small color="grey">search</v-icon>
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="searchPallets"
                @change="searchKey++"
              ></v-text-field>
            </v-chip>
          <v-btn text @click="palletModal = false">X</v-btn>
        </v-toolbar>
        <v-row class="mx-2">
          <v-col cols="12" sm="3" class="my-0 py-0">
            <v-list-item>
              <v-list-item-action>
                <v-icon color="grey">directions_boat</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ selectedVessel.vessel.name }} {{ selectedVessel.voyage }}
                </v-list-item-title>
                <v-list-item-subtitle> Vessel/Voyage </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" class="my-0 py-0">
            <v-list-item>
              <v-list-item-action>
                <v-icon color="grey">call_received</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="selectedVessel.consignee">
                  {{ selectedVessel.consignee.name }}
                </v-list-item-title>
                <v-list-item-title v-else> - </v-list-item-title>
                <v-list-item-subtitle> Consignee </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="3" class="my-0 py-0">
            <v-list-item>
              <v-list-item-action>
                <v-icon color="grey">anchor</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ selectedVessel.portOfLoad }}
                </v-list-item-title>
                <v-list-item-subtitle> Port of Load </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-card-text style="max-width: 100%; overflow-x: auto">
          <div :style="{ width: palletTableWidth }" class="mt-2 mx-1">
            <table :width="'100%'">
              <tr>
                <td
                  class="text-center mx-0 px-0"
                  style="
                    cursor: grab;
                    border-right: 1px solid grey;
                    min-height: 20px;
                    min-width: 20px;
                  "
                  v-for="(header, index) in palletHeaders"
                  :key="index"
                  :width="header.width ?? '100px'"
                >
                  <div>
                    <v-row align="center" justify="center" class="py-2">
                      <b style="font-size: 11px">{{ header.text }}</b>
                      <v-btn
                        v-if="!header.disableSort"
                        class="ml-1"
                        icon
                        x-small
                        @click="changePalletSortBy(header.value)"
                      >
                        <v-icon
                          v-if="sortPalletBy != header.value"
                          x-small
                          color="grey"
                          >arrow_upward</v-icon
                        >
                        <v-icon v-else-if="sortDesc" x-small
                          >arrow_downward</v-icon
                        >
                        <v-icon v-else small>arrow_upward</v-icon>
                      </v-btn>
                      <v-badge
                        v-if="!header.disableFilter"
                        :content="
                          palletFilters[header.value]
                            ? palletFilters[header.value].length
                            : false
                        "
                        dense
                        :value="
                          palletFilters[header.value] &&
                          palletFilters[header.value].length > 0
                        "
                        color="primary"
                        offset-y="10"
                      >
                        <v-menu offset-y left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              color="grey"
                              icon
                              ><v-icon small>filter_alt</v-icon></v-btn
                            >
                          </template>
                          <v-card
                            style="
                              background: var(--v-modal-base) !important;
                              border-radius: 20px;
                            "
                          >
                            <v-card-text>
                              <v-list
                                dense
                                style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                "
                              >
                                <v-text-field
                                  hide-details
                                  rounded
                                  dense
                                  outlined
                                  clearable
                                  prepend-inner-icon="search"
                                  placeholder="Search"
                                  autofocus
                                  v-model="searchPalletHeaders[header.value]"
                                  @input="updateSearchKey()"
                                ></v-text-field>
                                <v-list
                                  dense
                                  style="
                                    max-height: 50vh;
                                    overflow-y: auto;
                                    background: var(--v-modal-base) !important;
                                  "
                                  :key="searchKey"
                                >
                                  <v-list-item
                                    v-for="(item, index) in palletHeaderOptions(
                                      header.value
                                    )"
                                    :key="index"
                                  >
                                    <v-list-item-action class="pr-0">
                                      <v-checkbox
                                        v-model="palletFilters[header.value]"
                                        :value="item.value"
                                        hide-details
                                        dense
                                        @change="
                                          calculateFilteredPalletResults()
                                        "
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      palletHeaderOptions(header.value)
                                        .length == 0
                                    "
                                  >
                                    <v-list-item-content class="text-center">
                                      <span style="font-size: 12px"
                                        >No results found</span
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-badge>
                    </v-row>
                  </div>
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
            <v-virtual-scroll
              v-if="filterPallets.length > 0"
              :bench="10"
              :items="filterPallets"
              :height="calculatedPalletHeight"
              :width="'100%'"
              item-height="25"
              class="px-0 mx-0"
            >
              <template v-slot:default="{ item }">
                <div
                  class="text-center my-0 py-0"
                  :style="{
                    display: 'flex',
                    'flex-direction': 'row',
                    width: '100%',
                    'background-color': 'transparent',
                    'border-bottom': '0.25px solid grey',
                  }"
                >
                  <td
                    v-for="(header, index) in palletHeaders"
                    :key="index"
                    :style="{ width: header.width ?? '100px' }"
                    class="text-center my-0 py-0"
                  >
                    <div v-if="header.value == 'status'">
                      <v-chip
                        @click="
                          addToPalletFilter(item[header.value], header.value)
                        "
                        x-small
                        outlined
                        :color="getPalletStatusColor(item.status)"
                      >
                        <v-icon small left>{{
                          getPalletStatusIcon(item.status)
                        }}</v-icon>
                        {{ item.status }}
                      </v-chip>
                    </div>
                    <div
                      v-else-if="
                        header.value == 'inspectionManifestDate' ||
                        header.value == 'intakeTime'
                      "
                    >
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        @click="
                          addToPalletFilter(item[header.value], header.value)
                        "
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>

                    <div v-else>
                      <v-chip
                        v-if="item[header.value]"
                        @click="
                          addToPalletFilter(item[header.value], header.value)
                        "
                        small
                        class="primaryText--text"
                        outlined
                        style="border: none; font-size: 11px; z-index: 1"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                  </td>
                </div>
              </template>
            </v-virtual-scroll>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="discrepancyModal" width="90vw">
      <DiscrepancyCard
        :item="selectedDiscrepancy"
        @close="discrepancyModal = false"
        @refresh="getDiscrepancies(), (discrepancyModal = false)"
      />
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import DiscrepancyCard from "../StockLite/DiscrepancyModal.vue";
import PreviewStockModal from "../StockLite/PreviewStockModal.vue";
export default {
  components: {
    DiscrepancyCard,
    PreviewStockModal
  },
  data: () => ({
    alias: "",
    aliasKey: 250,
    aliasModal: false,
    colors: [
      "#2196F3",
      "#8BC34A",
      "#FFC107",
      "#9C27B0",
      "#00BCD4",
      "#3F51B5",
      "#009688",
      "#FF9800",
      "#607D8B",
    ],
    discrepancies: [],
    discrepancyModal: false,
    downloadingPalletExport: false,
    expanded: [],
    filteredPalletResults: [],
    headers: [
      {
        text: "Vessel",
        value: "vesselVoyage",
      },
      {
        text: "Port",
        value: "port",
        align: "center",
      },
      {
        text: "Sailed",
        value: "sailed",
        align: "center",
      },
      {
        text: "ETD Week",
        value: "etdWeekYear",
        align: "center",
      },
      {
        text: "Allocated",
        value: "allocated",
        align: "center",
      },
      {
        text: "Confirmed",
        value: "confirmed",
        align: "center",
      },
      {
        text: "Rejected",
        value: "rejected",
        align: "center",
      },
      {
        text: "Stuffed",
        value: "stuffed",
        align: "center",
      },
      {
        text: "Issues",
        value: "issues",
        align: "center",
      },
    ],
    loading: true,
    loadingAliases: false,
    loadingDiscrepancy: false,
    loadingOrganisations: false,
    loadingProductionAlias: false,
    loadingStockWarnings: false,
    loadingVessels: false,
    palletStatus: [
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Rejected", icon: "verified", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    organisations: {
      data: [],
    },
    pallets: [],
    palletModal: false,
    palletHeaders: [
      {
        text: "Status",
        value: "status",
        allowSort: true,
        allowFilter: true,
        width: "100px",
      },
      {
        text: "Location",
        value: "locationCode",
        allowSort: true,
        allowFilter: true,
        width: "100px",
      },
      {
        text: "CTO No",
        value: "ctoNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Barcode",
        value: "sscc",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        allowSort: true,
        allowFilter: false,
        width: "90px",
      },
      {
        text: "Pal. Qty",
        value: "palletQuantity",
        allowSort: true,
        allowFilter: false,
        width: "120px",
      },
      {
        text: "Ctns",
        value: "cartonQuantity",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Cust. Ref",
        value: "customerContainerRef",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Comm.",
        value: "commodityCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Var.",
        value: "varietyCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Grade",
        value: "grade",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Count",
        value: "countSize",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Pack",
        value: "packCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Mark",
        value: "mark",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Inv. Code",
        value: "inventoryCode",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Prod. Char",
        value: "productCharacteristic",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Target Market",
        value: "targetMarket",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Region",
        value: "targetRegion",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Country",
        value: "targetCountry",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "PUC",
        value: "puc",
        allowSort: true,
        allowFilter: true,
        width: "120px",
      },
      {
        text: "Producer",
        value: "producerName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Orch.",
        value: "orchard",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "PHC",
        value: "phc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Packhouse Name",
        value: "packhouseName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Date",
        value: "inspectionManifestDate",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Age",
        value: "inspectionAge",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Intake Waybill",
        value: "waybillNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Loc. Intake Date",
        value: "intakeTime",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Loc. Intake Age",
        value: "age",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Nett",
        value: "nettWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Gross",
        value: "grossWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
    ],
    previewStockType: null,
    previewLocationCode: null,
    previewKey: 125,
    previewStockModal: false,
    productionAliasModal: false,
    productionAliasItem: {},
    productionAliases: [],
    search: null,
    searchKey: 250,
    stores: [],
    updatingLinkedRecords: false,
    report: {
      labels: [],
      legend: [],
      data: [],
    },
    reportKey: 100,
    searchCustomer: null,
    searchPallets: null,
    searchPalletHeaders: {},
    selectedDiscrepancy: {},
    selectedVessel: null,
    sortPalletBy: null,
    sortPalletDesc: false,
    vessels: [],
    warnings: {
      location: [],
      product: [],
      variety: [],
    },
  }),
  computed: {
    calculatedPalletHeight() {
      let height = window.innerHeight;
      return height * 0.7 + "px";
    },
    filterOrganisations() {
      let result = this.organisations.data;
      if (this.searchCustomer) {
        result = result.filter((item) =>
          item.relatedOrganisation.name
            .toLowerCase()
            .includes(this.searchCustomer.toLowerCase())
        );
      }
      return result;
    },
    filterPallets() {
      let result = this.filteredPalletResults;
      if (this.searchPallets) {
        result = result.filter(x=>
        this.palletHeaders.some(y=>x[y.value] && (x[y.value].toString()).toLowerCase().includes(this.searchPallets.toLowerCase()))
        )
        // result = result.filter(
        //   (x) =>
        //     (x.sscc &&
        //       x.sscc
        //         .toLowerCase()
        //         .includes(this.searchPallets.toLowerCase())) ||
        //     (x.ctoNo &&
        //       x.ctoNo
        //         .toLowerCase()
        //         .includes(this.searchPallets.toLowerCase())) ||
        //     (x.containerNo &&
        //       x.containerNo
        //         .toLowerCase()
        //         .includes(this.searchPallets.toLowerCase()))
        // );
      }
      if (this.sortPalletBy) {
        result = result.sort((a, b) => {
          if (this.sortPalletDesc) {
            return a[this.sortPalletBy] < b[this.sortPalletBy] ? 1 : -1;
          } else {
            return a[this.sortPalletBy] > b[this.sortPalletBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    palletTableWidth() {
      let result = 0;
      this.palletHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 100);
      });
      return result + "px";
    },
    totalStock() {
      let result = this.stores
        .map((x) => x.totalStock)
        .reduce((a, b) => a + b, 0);
      return result;
    },
    totalAllocated() {
      let result = this.stores
        .map((x) => x.totalAllocated)
        .reduce((a, b) => a + b, 0);
      return result;
    },
    totalLoaded() {
      let result = this.stores
        .map((x) => x.totalLoaded)
        .reduce((a, b) => a + b, 0);
      return result;
    },
    totalUnallocated() {
      let result = this.stores
        .map((x) => x.totalUnallocated)
        .reduce((a, b) => a + b, 0);
      return result;
    },
    averageAge() {
      let result = this.stores
        .map((x) => x.averageAge)
        .reduce((a, b) => a + b, 0);
      return result;
    },
  },
  async created() {
    this.getProductionAliases();
    this.getDiscrepancies();
    // await this.getStockReport();
    // this.getStockWarnings();
    this.getRelations();
    this.resetPalletFilters();
    await this.getStoreOverview();
    this.getVesselSummary();
  },
  methods: {
    addToPalletFilter(value, field) {
      if (this.palletFilters[field].includes(value)) {
        this.palletFilters[field] = this.palletFilters[field].filter(
          (x) => x != value
        );
      } else {
        this.palletFilters[field].push(value);
      }
      let keys = Object.keys(this.palletFilters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.palletFilters[x].map((y) => ({
            field: x,
            fieldName: this.palletHeaders.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.calculateFilteredPalletResults();
    },
    calculateFilteredPalletResults() {
      let filterKeys = Object.keys(this.palletFilters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.palletFilters[x].map((y) => ({
            field: x,
            fieldName: this.palletHeaders.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      let result = [...this.pallets];
      let keys = Object.keys(this.palletFilters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.palletFilters[key] && this.palletFilters[key].length > 0) {
          result = result.filter((x) =>
            this.palletFilters[key].includes(x[key])
          );
        }
      }
      this.filteredPalletResults = result;
    },
    async downloadPalletExport() {
      this.downloadingPalletExport = true;
      let data = await this.$API.getStockExport({
        palletIds: this.filterPallets.map((x) => x.id),
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        data;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Stock_" + new Date().getTime() + ".xlsx";
      hiddenElement.click();
      this.downloadingPalletExport = false;
    },
    editProductionAlias(item) {
      this.productionAliasItem = {
        producer: item,
        producerId: item.id,
        aliases: item.aliases,
      };
      this.productionAliasModal = true;
      this.getProducerProductionAliases(item.id);
    },
    expandRow(item) {
      let findIndex = this.expanded.findIndex((x) => x.id == item.id);
      if (findIndex == -1) {
        this.expanded = [item];
      } else {
        this.expanded.splice(findIndex, 1);
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd/mm/yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd/mm/yyyy HH:MM");
      }
      return result;
    },
    async getDiscrepancies() {
      this.loadingDiscrepancy = true;
      this.discrepancies = await this.$API.getStockDiscrepancies();
      this.loadingDiscrepancy = false;
    },
    getPalletStatusColor(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.color : "grey";
    },
    getPalletStatusIcon(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.icon : "help";
    },
    async getProductionAliases() {
      this.loadingProductionAlias = true;
      this.productionAliases = await this.$API.getProductionAliases();
      this.loadingProductionAlias = false;
    },
    async getProducerProductionAliases(id) {
      this.loadingAliases = true;
      this.productionAliasItem.aliases = await this.$API.getProductionAliases({
        producerId: id,
      });
      this.loadingAliases = false;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      if (
        localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
      ) {
        this.organisations = JSON.parse(
          localStorage[
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          ]
        );
      }
      this.organisations = await this.$API.getRelation({});
      localStorage.setItem(
        "relatedOrganisations_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.organisations)
      );
      this.loadingOrganisations = false;
    },
    async getStockReport() {
      this.report = await this.$API.getStockReport();
      this.reportKey++;
      console.log(this.report);
    },
    async getStockWarnings() {
      this.loadingStockWarnings = true;
      this.warnings = await this.$API.getStockWarnings();
      this.loadingStockWarnings = false;
    },
    async getStoreOverview() {
      this.loading = true;
      this.stores = await this.$API.getStockStoreOverview();
      this.loading = false;
    },
    async getVesselSummary() {
      this.loadingVessels = true;
      this.vessels = await this.$API.getStockLiteVessels();
      this.loadingVessels = false;
    },
    palletHeaderOptions(value) {
      let result = [
        ...new Set(
          this.pallets.map((x) => ({
            name: x[value] ?? "(Blank)",
            value: x[value],
          }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchPalletHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x
              .toLowerCase()
              .includes(this.searchPalletHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    previewStock(locationCode = null, type = "TOTAL_STOCK") {
      this.previewStockType = type;
      this.previewLocationCode = locationCode;
      this.previewStockModal = true;
      this.previewKey++;
    },
    async removeAlias(alias) {
      await this.$API.updateProductionAlias({
        id: alias.id,
        isActive: false,
        isDeleted: true,
      });
      let index = this.productionAliasItem.aliases.findIndex(
        (item) => item.id == alias.id
      );
      this.productionAliasItem.aliases.splice(index, 1);
      this.aliasKey++;
    },
    resetPalletFilters() {
      this.palletFilters = {};
      let keys = this.palletHeaders.map((x) => x.value);
      keys.forEach((x) => {
        this.searchPalletHeaders[x] = null;
        this.palletFilters[x] = [];
      });
      this.calculateFilteredPalletResults();
    },
    async setProducer(item) {
      this.productionAliasItem.producer = item.relatedOrganisation;
      this.productionAliasItem.producerId = item.relatedOrganisation.id;
      this.getProducerProductionAliases(this.productionAliasItem.producerId);
    },
    async saveAlias() {
      let result = await this.$API.createProductionAlias({
        code: this.alias,
        producerId: this.productionAliasItem.producerId,
      });
      this.alias = null;
      this.aliasModal = false;
      this.productionAliasItem.aliases.push(result);
    },
    changePalletSortBy(value) {
      if (this.sortPalletBy == value) {
        if (this.sortPalletDesc) {
          this.sortPalletBy = null;
          this.sortPalletDesc = false;
        } else {
          this.sortPalletDesc = true;
        }
      } else {
        this.sortPalletBy = value;
        this.sortPalletDesc = false;
      }
    },
    async updateLinkedRecords() {
      this.updatingLinkedRecords = true;
      await this.$API.updateProductionAliasStock({
        producerId: this.productionAliasItem.producerId,
        aliases: this.productionAliasItem.aliases.map((x) => x.code),
      });
      this.$message.success("Successfully updated linked records");
      this.updatingLinkedRecords = false;
    },
    updateSearchKey(){
      this.searchKey++;
    },
    async viewPallets(filter, port, status) {
      this.selectedVessel = filter;
      this.selectedVessel.portOfLoad = port;

      let query = {
        ...filter.filter,
        port: port,
        status: status,
      };
      this.pallets = await this.$API.getStockDashboardPallets(query);
      this.calculateFilteredPalletResults();
      this.palletModal = true;
    },
    viewDiscrepancy(item) {
      this.selectedDiscrepancy = item;
      this.discrepancyModal = true;
    },
  },
};
</script>
<template>
  <div>
    <v-card flat :loading="loading">
      <v-toolbar flat>
        <v-toolbar-title>
          {{ item.ctoNo }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outlined dense rounded class="mx-1" color="success" style="text-transform: none" @click="confirmAcceptModal = true">Accept</v-btn>
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-tabs v-model="palletTab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab> Discrepancies </v-tab>
        <v-tab> Stuffed Pallets </v-tab>
      </v-tabs>
      <v-card-text style="max-width: 100%; overflow-x: auto">
        <div v-if="palletTab == 0">
          <div :style="{ width: palletTableWidth }" class="mt-2 mx-1">
            <table :width="'100%'">
              <tr>
                <td
                  class="text-center mx-0 px-0"
                  style="
                    cursor: grab;
                    border-right: 1px solid grey;
                    min-height: 20px;
                    min-width: 20px;
                  "
                  v-for="(header, index) in palletHeaders"
                  :key="index"
                  :width="header.width ?? '100px'"
                >
                  <div>
                    <v-row align="center" justify="center" class="py-2">
                      <b style="font-size: 11px">{{ header.text }}</b>
                    </v-row>
                  </div>
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
            <v-virtual-scroll
              v-if="issuePallets.length > 0"
              :bench="10"
              :items="issuePallets"
              :height="'400px'"
              :width="'100%'"
              item-height="25"
              class="px-0 mx-0"
            >
              <template v-slot:default="{ item }">
                <div
                  class="text-center my-0 py-0"
                  :style="{
                    display: 'flex',
                    'flex-direction': 'row',
                    width: '100%',
                    'background-color': item.recordType != 'Allocated Pallets' && item.recordType != 'Stuffed Pallets'? 'transparent': 'var(--v-toolbar-lighten1) !important',
                    'border-bottom': '0.25px solid grey',
                  }"
                >
                <div v-if="item.recordType != 'Allocated Pallets' && item.recordType != 'Stuffed Pallets'">
                    <td
                    v-for="(header, index) in palletHeaders"
                    :key="index"
                    :style="{ width: header.width ?? '100px' }"
                    class="text-center my-0 py-0"
                  >
                    <div v-if="header.value == 'status'">
                      <v-chip
                        x-small
                        outlined
                        :color="getPalletStatusColor(item.status)"
                      >
                        <v-icon left small>{{
                          getPalletStatusIcon(item.status)
                        }}</v-icon>
                        {{ item.status }}
                      </v-chip>
                    </div>
                    <div
                      v-else-if="
                        header.value == 'inspectionManifestDate' ||
                        header.value == 'intakeTime'
                      "
                    >
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>

                    <div v-else>
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        style="border: none; font-size: 11px; z-index: 1"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                  </td>
                </div>
                <div v-else-if="item.recordType == 'Allocated Pallets'">
                    <td :colspan="palletHeaders.length">
                    <v-icon color="blue" class="mr-2" small> fact_check </v-icon> On Instruction but not Stuffed
                    </td>
                </div>
                <div v-else-if="item.recordType == 'Stuffed Pallets'">
                    <td :colspan="palletHeaders.length">
                    <v-icon color="lime" class="mr-2" small> check_circle_outline </v-icon> Stuffed but not part of Instruction
                    </td>
                </div>
                  
                </div>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
        <div v-else-if="palletTab == 1">
          <div :style="{ width: palletTableWidth }" class="mt-2 mx-1">
            <table :width="'100%'">
              <tr>
                <td
                  class="text-center mx-0 px-0"
                  style="
                    cursor: grab;
                    border-right: 1px solid grey;
                    min-height: 20px;
                    min-width: 20px;
                  "
                  v-for="(header, index) in palletHeaders"
                  :key="index"
                  :width="header.width ?? '100px'"
                >
                  <div>
                    <v-row align="center" justify="center" class="py-2">
                      <b style="font-size: 11px">{{ header.text }}</b>
                    </v-row>
                  </div>
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
            <v-virtual-scroll
              v-if="allPallets.length > 0"
              :bench="10"
              :items="allPallets"
              :height="calculatedPalletHeight"
              :width="'100%'"
              item-height="25"
              class="px-0 mx-0"
            >
              <template v-slot:default="{ item }">
                <div
                  class="text-center my-0 py-0"
                  :style="{
                    display: 'flex',
                    'flex-direction': 'row',
                    width: '100%',
                    'background-color': 'transparent',
                    'border-bottom': '0.25px solid grey',
                  }"
                >
                  <td
                    v-for="(header, index) in palletHeaders"
                    :key="index"
                    :style="{ width: header.width ?? '100px' }"
                    class="text-center my-0 py-0"
                  >
                    <div v-if="header.value == 'status'">
                      <v-chip
                        x-small
                        outlined
                        :color="getPalletStatusColor(item.status)"
                      >
                        <v-icon left small>{{
                          getPalletStatusIcon(item.status)
                        }}</v-icon>
                        {{ item.status }}
                      </v-chip>
                    </div>
                    <div
                      v-else-if="
                        header.value == 'inspectionManifestDate' ||
                        header.value == 'intakeTime'
                      "
                    >
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        style="border: none"
                      >
                        {{ formatDate(item[header.value]) }}
                      </v-chip>
                    </div>

                    <div v-else>
                      <v-chip
                        v-if="item[header.value]"
                        small
                        class="primaryText--text"
                        outlined
                        style="border: none; font-size: 11px; z-index: 1"
                      >
                        {{ item[header.value] }}
                      </v-chip>
                    </div>
                  </td>
                </div>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmAcceptModal" width="500px">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like to accept these discrepancies?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmAcceptModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              :loading="accepting"
              @click="acceptStockDiscrepancy()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    accepting: false,
    confirmAcceptModal: false,
    loading: false,
    palletStatus: [
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Rejected", icon: "verified", color: "red" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    palletHeaders: [
      {
        text: "Status",
        value: "status",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Location",
        value: "locationCode",
        allowSort: true,
        allowFilter: true,
        width: "100px",
      },
      {
        text: "CTO No",
        value: "ctoNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Barcode",
        value: "sscc",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        allowSort: true,
        allowFilter: false,
        width: "90px",
      },
      {
        text: "Pal. Qty",
        value: "palletQuantity",
        allowSort: true,
        allowFilter: false,
        width: "120px",
      },
      {
        text: "Ctns",
        value: "cartonQuantity",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Cust. Ref",
        value: "customerContainerRef",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Comm.",
        value: "commodityCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Var.",
        value: "varietyCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Grade",
        value: "grade",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Count",
        value: "countSize",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Pack",
        value: "packCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Mark",
        value: "mark",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Inv. Code",
        value: "inventoryCode",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Prod. Char",
        value: "productCharacteristic",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Target Market",
        value: "targetMarket",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Region",
        value: "targetRegion",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Country",
        value: "targetCountry",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "PUC",
        value: "puc",
        allowSort: true,
        allowFilter: true,
        width: "120px",
      },
      {
        text: "Producer",
        value: "producerName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Orch.",
        value: "orchard",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "PHC",
        value: "phc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Packhouse Name",
        value: "packhouseName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Date",
        value: "inspectionManifestDate",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Age",
        value: "inspectionAge",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Intake Waybill",
        value: "waybillNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Loc. Intake Date",
        value: "intakeTime",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Loc. Intake Age",
        value: "age",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Nett",
        value: "nettWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Gross",
        value: "grossWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
    ],

    palletTab: 0,
    detail: [],
  }),
  watch: {
    "item.id": {
      immediate: true,
      handler(val) {
        this.getItemDetails(val);
      },
    },
  },
  computed: {
    allPallets() {
      let result = this.detail.filter(
        (x) =>
          x.bookingContainerId == this.item.id &&
          x.loadedContainerId == this.item.id
      );
      return result;
    },
    allocatedIssues() {
      let result = this.detail.filter(
        (x) =>
          x.bookingContainerId == this.item.id &&
          x.loadedContainerId != this.item.id
      );
      return result;
    },
    stuffedIssues() {
      let result = this.detail.filter(
        (x) =>
          x.bookingContainerId != this.item.id &&
          x.loadedContainerId == this.item.id
      );
      return result;
    },
    issuePallets(){
        let result = [
            {recordType: 'Allocated Pallets'},
            ...this.allocatedIssues,
            {recordType: 'Stuffed Pallets'},
            ...this.stuffedIssues
        ]
        return result;
    },
    calculatedPalletHeight() {
      let height = window.innerHeight;
      return height * 0.7 + "px";
    },
    palletTableWidth() {
      let result = 0;
      this.palletHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 100);
      });
      return result + "px";
    },
  },
  methods: {
    async acceptStockDiscrepancy(){
        try {
            this.accepting = true;
            await this.$API.acceptStockDiscrepancy(this.item.id);
            this.accepting = false;
            this.confirmAcceptModal = false;
            this.$emit('refresh');
            this.$message.success('Successfully accepted stock discrepancy');
        } catch (error) {
            console.log(error);
            this.$message.error('Failed to accept stock discrepancy');
        }
    
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd/mm/yyyy");
      }
      return result;
    },
    async getItemDetails(id) {
      try {
        this.loading = true;
        this.detail = await this.$API.getStockDiscrepancyDetail(id);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    getPalletStatusColor(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.color : "grey";
    },
    getPalletStatusIcon(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.icon : "help";
    },
  },
};
</script>
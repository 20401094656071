var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.item.ctoNo)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",staticStyle:{"text-transform":"none"},attrs:{"outlined":"","dense":"","rounded":"","color":"success"},on:{"click":function($event){_vm.confirmAcceptModal = true}}},[_vm._v("Accept")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("X")])],1),_c('v-tabs',{model:{value:(_vm.palletTab),callback:function ($$v) {_vm.palletTab=$$v},expression:"palletTab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_vm._v(" Discrepancies ")]),_c('v-tab',[_vm._v(" Stuffed Pallets ")])],1),_c('v-card-text',{staticStyle:{"max-width":"100%","overflow-x":"auto"}},[(_vm.palletTab == 0)?_c('div',[_c('div',{staticClass:"mt-2 mx-1",style:({ width: _vm.palletTableWidth })},[_c('table',{attrs:{"width":'100%'}},[_c('tr',_vm._l((_vm.palletHeaders),function(header,index){return _c('td',{key:index,staticClass:"text-center mx-0 px-0",staticStyle:{"cursor":"grab","border-right":"1px solid grey","min-height":"20px","min-width":"20px"},attrs:{"width":header.width ?? '100px'}},[_c('div',[_c('v-row',{staticClass:"py-2",attrs:{"align":"center","justify":"center"}},[_c('b',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(header.text))])])],1)])}),0)]),_c('v-divider'),(_vm.issuePallets.length > 0)?_c('v-virtual-scroll',{staticClass:"px-0 mx-0",attrs:{"bench":10,"items":_vm.issuePallets,"height":'400px',"width":'100%',"item-height":"25"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"text-center my-0 py-0",style:({
                  display: 'flex',
                  'flex-direction': 'row',
                  width: '100%',
                  'background-color': item.recordType != 'Allocated Pallets' && item.recordType != 'Stuffed Pallets'? 'transparent': 'var(--v-toolbar-lighten1) !important',
                  'border-bottom': '0.25px solid grey',
                })},[(item.recordType != 'Allocated Pallets' && item.recordType != 'Stuffed Pallets')?_c('div',_vm._l((_vm.palletHeaders),function(header,index){return _c('td',{key:index,staticClass:"text-center my-0 py-0",style:({ width: header.width ?? '100px' })},[(header.value == 'status')?_c('div',[_c('v-chip',{attrs:{"x-small":"","outlined":"","color":_vm.getPalletStatusColor(item.status)}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.getPalletStatusIcon(item.status)))]),_vm._v(" "+_vm._s(item.status)+" ")],1)],1):(
                      header.value == 'inspectionManifestDate' ||
                      header.value == 'intakeTime'
                    )?_c('div',[(item[header.value])?_c('v-chip',{staticClass:"primaryText--text",staticStyle:{"border":"none"},attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item[header.value]))+" ")]):_vm._e()],1):_c('div',[(item[header.value])?_c('v-chip',{staticClass:"primaryText--text",staticStyle:{"border":"none","font-size":"11px","z-index":"1"},attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(item[header.value])+" ")]):_vm._e()],1)])}),0):(item.recordType == 'Allocated Pallets')?_c('div',[_c('td',{attrs:{"colspan":_vm.palletHeaders.length}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","small":""}},[_vm._v(" fact_check ")]),_vm._v(" On Instruction but not Stuffed ")],1)]):(item.recordType == 'Stuffed Pallets')?_c('div',[_c('td',{attrs:{"colspan":_vm.palletHeaders.length}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"lime","small":""}},[_vm._v(" check_circle_outline ")]),_vm._v(" Stuffed but not part of Instruction ")],1)]):_vm._e()])]}}],null,false,1657282146)}):_vm._e()],1)]):(_vm.palletTab == 1)?_c('div',[_c('div',{staticClass:"mt-2 mx-1",style:({ width: _vm.palletTableWidth })},[_c('table',{attrs:{"width":'100%'}},[_c('tr',_vm._l((_vm.palletHeaders),function(header,index){return _c('td',{key:index,staticClass:"text-center mx-0 px-0",staticStyle:{"cursor":"grab","border-right":"1px solid grey","min-height":"20px","min-width":"20px"},attrs:{"width":header.width ?? '100px'}},[_c('div',[_c('v-row',{staticClass:"py-2",attrs:{"align":"center","justify":"center"}},[_c('b',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(header.text))])])],1)])}),0)]),_c('v-divider'),(_vm.allPallets.length > 0)?_c('v-virtual-scroll',{staticClass:"px-0 mx-0",attrs:{"bench":10,"items":_vm.allPallets,"height":_vm.calculatedPalletHeight,"width":'100%',"item-height":"25"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"text-center my-0 py-0",style:({
                  display: 'flex',
                  'flex-direction': 'row',
                  width: '100%',
                  'background-color': 'transparent',
                  'border-bottom': '0.25px solid grey',
                })},_vm._l((_vm.palletHeaders),function(header,index){return _c('td',{key:index,staticClass:"text-center my-0 py-0",style:({ width: header.width ?? '100px' })},[(header.value == 'status')?_c('div',[_c('v-chip',{attrs:{"x-small":"","outlined":"","color":_vm.getPalletStatusColor(item.status)}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(_vm.getPalletStatusIcon(item.status)))]),_vm._v(" "+_vm._s(item.status)+" ")],1)],1):(
                      header.value == 'inspectionManifestDate' ||
                      header.value == 'intakeTime'
                    )?_c('div',[(item[header.value])?_c('v-chip',{staticClass:"primaryText--text",staticStyle:{"border":"none"},attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item[header.value]))+" ")]):_vm._e()],1):_c('div',[(item[header.value])?_c('v-chip',{staticClass:"primaryText--text",staticStyle:{"border":"none","font-size":"11px","z-index":"1"},attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(item[header.value])+" ")]):_vm._e()],1)])}),0)]}}],null,false,1098134327)}):_vm._e()],1)]):_vm._e()])],1),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.confirmAcceptModal),callback:function ($$v) {_vm.confirmAcceptModal=$$v},expression:"confirmAcceptModal"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-spacer')],1),_c('v-card-text',[_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" Are you sure you would like to accept these discrepancies? ")]),_c('v-row',{staticClass:"mt-5 mb-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red","text":"","small":""},on:{"click":function($event){_vm.confirmAcceptModal = false}}},[_vm._v("No")]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success","text":"","small":"","loading":_vm.accepting},on:{"click":function($event){return _vm.acceptStockDiscrepancy()}}},[_vm._v("Yes")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }